import React from "react";
import Logo from "common/components/UIElements/Logo";
import Image from "common/components/Image";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import NavbarWrapper from "./navbar.style";
import LogoImage from "common/assets/image/mercadia/logo-cart.svg";
import LogoM from "common/assets/image/mercadia/logo.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navbar = () => {
  return (
    <NavbarWrapper className="navbar">
      <Container>
        <div>
          <ul>
            {/* <AnchorLink href="#home"></AnchorLink> */}

            <AnchorLink className="brand" href="#home">
              <Image src={LogoM} alt="Mercadia" className="main-logo" />
              Mercadia
            </AnchorLink>

            <AnchorLink href="#faq">Preguntas frecuentes</AnchorLink>

            {/* <a
              className="nav"
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Preguntas frecuentes
            </a> */}
          </ul>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
