import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import SectionHeading from "../SectionHeading";
import SectionWrapper, { ContentWrapper, FaqItem } from "./faq.style";

const Faq = () => {
  const data = useStaticQuery(graphql`
    query {
      mercadiaJson {
        faq {
          title
          slogan
          faqs {
            id
            question
            answer
          }
        }
      }
    }
  `);

  const { slogan, title, faqs } = data.mercadiaJson.faq;
  return (
    <SectionWrapper id="faq">
      <Container>
        <Fade up delay={100}>
          <SectionHeading slogan={slogan} title={title} />
        </Fade>
        <ContentWrapper>
          {data.mercadiaJson.faq.faqs.map((faq) => (
            <Fade key={faq.id} up delay={100 * faq.id}>
              <FaqItem>
                <Heading as="h4" content={faq.question} />
                <Text content={faq.answer} />
              </FaqItem>
            </Fade>
          ))}
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Faq;
