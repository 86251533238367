import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Fade from "react-reveal/Fade";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import FeatureSliderWrapper from "./featureSlider.style";
import Image1 from "common/assets/image/mercadia/slides/device_02.jpg";
import Image2 from "common/assets/image/mercadia/slides/device_01.jpg";
import Image3 from "common/assets/image/mercadia/slides/device_03.jpg";
// import Image4 from "common/assets/image/mercadia/slides/device_02.jpg";
// import Image5 from "common/assets/image/mercadia/slides/device_01.jpg";

const images = [
  {
    original: `${Image1}`,
    originalAlt: "store one",
  },
  {
    original: `${Image2}`,
    originalAlt: "store two",
  },
  {
    original: `${Image3}`,
    originalAlt: "store three",
  },
  // {
  //   original: `${Image4}`,
  //   originalAlt: "store four",
  // },
  // {
  //   original: `${Image5}`,
  //   originalAlt: "store five",
  // },
  // {
  //   original: `${Image6}`,
  //   originalAlt: "store six",
  // },
];

const FeatureSlider = ({ secTitleWrapper, secTitle, secDescription }) => {
  return (
    <FeatureSliderWrapper id="keyfeature">
      <div className="FeatureSliderInner">
        <span> </span>
        <span> </span>
        <span> </span>
      </div>
      <Container noGutter mobileGutter width="100%" className="container">
        <Box {...secTitleWrapper}>
          <Fade up>
            <Heading
              {...secTitle}
              content="Disfruta en casa de tus restaurantes y tiendas favoritas"
            />
          </Fade>
          <Fade up>
            <Text
              {...secDescription}
              content="No hace falta salir del hogar para saborear los platos que más te gustan o hacer las compras del mercado."
            />
          </Fade>
        </Box>
        <Box className="FeatureSlider">
          <ImageGallery
            items={images}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={true}
            autoPlay
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
  secTitleWrapper: {
    mb: ["65px", "65px", "70px", "70px", "70px"],
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "36px"],
    fontWeight: "800",
    color: "#15172C",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "30px"],
    textAlign: "center",

    ml: "auto",
    mr: "auto",
    maxWidth: "740px",
    padding: "0 20px 0 20px",

    // fontFamily: 'Poppins',
  },
  secDescription: {
    fontSize: ["18px", "18px"],
    fontWeight: "400",
    color: "#15172C",
    lineHeight: "1.5",
    mb: "0",
    textAlign: "center",
    width: ["360px", "460px"],
    maxWidth: "100%",
    ml: "auto",
    mr: "auto",
    fontFamily: "Lato",
  },
};

export default FeatureSlider;
