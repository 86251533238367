import React from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { theme } from "common/theme/mercadia";
import { ResetCSS } from "common/assets/css/style";
import Sticky from "react-stickynode";
import TopBar from "containers/Mercadia/TopBar";
import Navbar from "containers/Mercadia/Navbar";
import Banner from "containers/Mercadia/Banner";
import CustomerSupport from "containers/Mercadia/CustomerSupport";
import KeyFeatures from "containers/Mercadia/KeyFeatures";
import ChooseUs from "containers/Mercadia/ChooseUs";
import Experiences from "containers/Mercadia/Experience";
import FeatureSlider from "containers/Mercadia/FeatureSlider";
import Pricing from "containers/Mercadia/Pricing";
import Testimonials from "containers/Mercadia/Testimonials";
import AvailableStore from "containers/Mercadia/AvailableStore";
import Faqs from "containers/Mercadia/Faq";
import ContactUs from "containers/Mercadia/ContactUs";
import Footer from "containers/Mercadia/Footer";
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "containers/Mercadia/mercadia.style";
import "@redq/reuse-modal/es/index.css";

import Seo from "components/seo";

const Mercadia = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Mercadia" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            {/* <TopBar /> */}
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            {/* <Experiences /> */}
            {/* <FeatureSlider /> */}
            {/* <CustomerSupport /> */}
            {/* <KeyFeatures /> */}
            {/* <ChooseUs /> */}
            {/* <FeatureSlider /> */}
            {/* <Pricing /> */}
            {/* <Testimonials /> */}
            {/* <AvailableStore /> */}
            <Faqs />
            {/* <ContactUs /> */}
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};

export default Mercadia;
