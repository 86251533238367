import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { playCircle } from "react-icons-kit/fa/playCircle";
import PlayButton from "common/assets/image/playstore.svg";
import CommissionImage from "common/assets/image/mercadia/key_feature/commission.svg";
import PostImage from "common/assets/image/mercadia/key_feature/post.svg";
import ChatImage from "common/assets/image/mercadia/key_feature/chat.svg";
import { openModal, closeModal } from "@redq/reuse-modal";
import Text from "common/components/Text";
import Button from "common/components/Button";
import Image from "common/components/Image";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import BannerWrapper, {
  BannerContent,
  BannerFeatures,
  BannerImage,
  BannerImageMobile,
  ButtonGroup,
  VideoWrapper,
} from "./banner.style";

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/hW98BFnVCm8"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Banner = () => {
  const Data = useStaticQuery(graphql`
    query {
      appScreenshot: file(relativePath: { eq: "image/banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 618
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bannerImgMobile: file(relativePath: { eq: "image/banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 618
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <BannerWrapper id="home">
      <Container className="banner">
        <BannerContent>
          <Fade up delay={100}>
            <Heading as="h1" content="Marketplace para emprendedores" />
          </Fade>

          <Fade up delay={200}>
            <BannerFeatures>
              <div className="feature">
                <Image src={PostImage} alt="Comission" />
                <Text content="Reciba comentarios de su comunidad" />
              </div>
              <div className="feature">
                <Image src={ChatImage} alt="Comission" />
                <Text content="Comuníquese con sus clientes" />
              </div>
              <div className="feature">
                <Image src={CommissionImage} alt="Comission" />
                <Text content="Pague solo un 2.9% de comisión" />
              </div>
            </BannerFeatures>
          </Fade>

          <Fade up delay={300}>
            <div className="CallToAction">
              <div className="btn-list">
                <a
                  className="btn btn-primary"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=co.mercadia.android"
                >
                  Explorar negocios
                </a>
                <a
                  className="btn btn-secondary"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=co.mercadia.store"
                >
                  Comenzar a vender
                </a>
              </div>
            </div>

            {/* <ButtonGroup>
              <Button
                onClick={toPlayStore}
                className="primary"
                title="Descargar"
              />
              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={playCircle} />}
                iconPosition="left"
                title="How it works"
                onClick={handleVideoModal}
              />
            </ButtonGroup> */}
          </Fade>
        </BannerContent>
        <BannerImage>
          <GatsbyImage
            src={
              (Data.appScreenshot !== null) | undefined
                ? Data.appScreenshot.childImageSharp.gatsbyImageData
                : {}
            }
            alt="App Screenshot"
          />
        </BannerImage>
        <BannerImageMobile>
          <GatsbyImage
            src={
              (Data.bannerImgMobile !== null) | undefined
                ? Data.bannerImgMobile.childImageSharp.gatsbyImageData
                : {}
            }
            alt="App Screenshot"
          />
        </BannerImageMobile>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
